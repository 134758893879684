import React from 'react';
import PropType from 'prop-types';
import CCLButton from './cclButton';
import './cclExplore.scss';

const CCLExplore = props => {
  let bgStyle = {
    backgroundImage: 'url(' + props.image + ')',
  };

  return (
    <div className="explore-section" id={props.cardId}>
      <div className={`explore-card ${props.isFinancialCard ? 'finacial' : ''}`} style={bgStyle}>
        {props.expandedContent ? (
          <div className="explore-story expandable">
            <h3>{props.label}</h3>
            <CCLButton style={props.btnClass || "ghost"} label={props.cta} link={props.link}/>
          </div>
        ) : (props.isFinancialCard ? 
              <div className="stretch">
                <div className="label">{props.label}</div>
                <div className="description">{props.description}</div>
                <CCLButton style={props.btnClass || "outline"} tagName="a" label={props.cta} arrow="true" link={props.link}/>
              </div>
            :<div className="explore-cta">
              <h3>{props.label}</h3>
              <CCLButton style={props.btnClass || "outline"} label={props.cta} arrow="true" link={props.link}/>
            </div>)
        }
      </div>
    </div>
  );
};

CCLExplore.propType = {
  title: PropType.string.isRequired,
  cta: PropType.string.isRequired,
  image: PropType.string.isRequired
};

export default CCLExplore;